import React from 'react';
import { BrowserRouter, Route, Routes, Switch } from 'react-router-dom';
import Home from './pages/home';
import Tannery from './pages/tannery';
import Tanning from './pages/tannery/tanning';
import Salting from './pages/tannery/salting';
import Churchill from './pages/churchill';
import Makers from './pages/makers';
import Survey from './pages/survey';
import About from './pages/home/about';
import Support from './pages/home/support';
import NotFound from './pages/home/404';
import Template from './pages/home/template';
import Contact from './pages/contact';
import Header from "./components/header";
import Footer from "./components/footer";
import ScrollToTop from "./components/ScrollToTop";

const App = () => {
  return (
    <>
       <Header></Header>
       <ScrollToTop>
         <Routes>
           <Route path="/" element={<Home />} />
           <Route path="/the-tannery" element={<Tannery />} />
           <Route path="/the-tannery/veg-tanning" element={<Tanning />} />
           <Route path="/the-tannery/salting-hides" element={<Salting />} />
           <Route path="/churchill-fellowship" element={<Churchill />} />
           <Route path="/makers-directory" element={<Makers />} />
           <Route path="/survey" element={<Survey />} />
           <Route path="/about-us" element={<About />} />
           <Route path="/support" element={<Support />} />
           <Route path="/contact-us" element={<Contact />} />
           <Route path="/template" element={<Template />} />
           <Route path="*" element={<NotFound />} />
         </Routes>
       </ScrollToTop>
       <Footer></Footer>
    </>
  );
 };
 
 export default App;