import React from 'react';
import {Link} from 'react-router-dom';
import './template.css';

import ImagePic1 from "../../assets/images/pic1.jpg";
import ImagePic2 from "../../assets/images/pic2.jpg";
import ImagePic3 from "../../assets/images/pic3.jpg";
import ImagePic4 from "../../assets/images/pic4.jpg";
import ImageSerPic from "../../assets/images/ser_pic.png";
import ImageIcon1 from "../../assets/images/icon1.png";
import ImageIcon2 from "../../assets/images/icon2.png";
import ImageIcon3 from "../../assets/images/icon3.png";
import ImageCopy from "../../assets/images/copy.png";
import ImageLogo from "../../assets/images/logo.png";

function App() {
    const title = 'Home';
    document.title = global.AppName;
    return (
        <>
            {/* start slider */}
            <div className="slider_bg">
                <div className="wrap">
                    <div className="slider" id="home">
                        <div className="slider_text">
                            <h2>we are bloom</h2>
                            <h3>We do digital &amp; we do it with a difference</h3>
                        </div>
                    </div>
                </div>
            </div>
            {/* 728x90 */}

        {/* start main */}
        <div className="main_bg">
            <div className="wrap">
                <div className="main" id="about">
                    <div className="main_text">
                        <h2>the bloom story </h2>
                        <h3>some things you should know about us</h3>
                    </div>	
                    <div className="content_bg">
                        <div className="main_pic">
                            <a className="btn" href="#"> from humble beginnings </a>
                        </div>
                    </div>	
                    {/*  start tabs_style */}
                    <section className="tabs">
                        <input id="tab-1" type="radio" name="radio-set" className="tab-selector-1" checked="checked"></input>
                        <label for="tab-1" className="tab-label-1">history</label>
                
                        <input id="tab-2" type="radio" name="radio-set" className="tab-selector-2"></input>
                        <label for="tab-2" className="tab-label-2">awards</label>
                
                        <input id="tab-3" type="radio" name="radio-set" className="tab-selector-3"></input>
                        <label for="tab-3" className="tab-label-3">philosophy</label>
                
                        <div className="clear-shadow"></div>
                        <div className="content_tab">
                            <div className="content-1">
                                <a className="tab_left">
                                    <h2>01. history</h2>
                                    <h3>a bit about our past</h3>
                                </a>
                                <a className="tab_right">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>
                                    <p className="top hide">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. </p>
                                </a>
                                <div className="clear"></div>
                            </div>
                            <div className="content-2">
                                <a className="tab_left">
                                    <h2>02. awards</h2>
                                    <h3>a bit about our awards</h3>
                                </a>
                                <a className="tab_right">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>
                                    <p className="top hide">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. </p>
                                </a>
                                <div className="clear"></div>
                            </div>
                            <div className="content-3">
                                <a className="tab_left">
                                    <h2>03. philosophy</h2>
                                    <h3>a bit our philosophy</h3>
                                </a>
                                <a className="tab_right">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>
                                    <p className="top hide">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. </p>
                                </a>
                                <div className="clear"></div>
                            </div>
                        </div>
                    </section>
                    {/* end tabs_style */}
                    <div className="clear"></div>
                </div>
            </div>
        </div>
        {/* 728x90 */}

        {/* start work-page */}
        <div className="work_bg">
            <div className="wrap">
                <div className="work" id="work">
                    <div className="main_text1">
                        <h2>our latest work </h2>
                        <h3>some projects we've worked on of late</h3>
                    </div>		
                    {/* start grids_of_2 */}
                    <div className="grids_of_2">
                        <div className="grid_1_of_2">
                            <div className="grid_pic">
                                <img src={ImagePic1} alt="" />
                            </div>
                            <div className="grid_text">
                                <h2>runner</h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                            </div>
                        </div>
                        <div className="grid_1_of_2">
                            <div className="grid_pic">
                                <img src={ImagePic2} alt=""></img>
                            </div>
                            <div className="grid_text">
                                <h2>velo</h2>
                                <p>There are many variations of passages of Lorem Ipsum but the majority have suffered  in some form, by injected humour, or randomised words which don't  when an unknown printer took look even slightly believable. </p>
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>	
                    <div className="grids_of_2">
                        <div className="grid_1_of_2">
                            <div className="grid_pic">
                                <img src={ImagePic3} alt=""></img>
                            </div>
                            <div className="grid_text">
                                <h2>harriers</h2>
                                <p>There are many variations of passages of Lorem Ipsum but the majority have suffered  in some form, by injected humour, or randomised words which don't  when an unknown printer took look even slightly believable. </p>
                            </div>
                        </div>
                        <div className="grid_1_of_2">
                            <div className="grid_pic">
                                <img src={ImagePic4} alt=""></img>
                            </div>
                            <div className="grid_text">
                                <h2>striders</h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>	
                    {/* end grids_of_2 */}
                    <div className="wrk_btn">
                        <a className="popup-with-zoom-anim" href="#small-dialog">view more projects</a>
                    </div>
                </div>
            </div>
        </div>
        {/* start service-page */}
        <div className="service_bg">
            <div className="wrap">
                <div className="service" id="service">
                    <div className="main_text1">
                        <h2>happy clients </h2>
                        <h3>some kind words from our clients </h3>
                    </div>	
                    <div className="service_main">
                        <div className="service_pic">
                            <img src={ImageSerPic} alt=""></img>
                        </div>
                        <div className="service_list">
                            <p>"The standard chunk of Lorem Ipsum is not simply random text. It has roots in a piece of classNameical Latin literature Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham."</p>
                            <h3>albert camus</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* start contact-page */}
        <div className="contact_bg">
            <div className="wrap">
                <div className="contact" id="contact">
                    <div className="main_text1">
                        <h2>get in touch </h2>
                        <h3>fill in the form &amp; we'll be in touch </h3>
                    </div>		
                    <div className="span_of_2">
                        <div className="span_1_of_2">
                            <div className="contact-form">
                            <form method="post">
                                        <div>
                                            <span><label>name</label></span>
                                            <span><input name="userName" type="text" className="textbox"></input></span>
                                            <div className="clear"></div>
                                        </div>
                                        <div>
                                            <span><label>e-mail</label></span>
                                            <span><input name="userEmail" type="text" className="textbox"></input></span>
                                            <div className="clear"></div>
                                        </div>
                                        <div>
                                            <span><label>mobile</label></span>
                                            <span><input name="userPhone" type="text" className="textbox"></input></span>
                                            <div className="clear"></div>
                                        </div>
                                        <div>
                                            <span><label>subject</label></span>
                                            <span><textarea name="userMsg"> </textarea></span>
                                            <div className="clear"></div>
                                        </div>
                                    <div>
                                            <span><input type="submit" className="" value="Send"></input></span>
                                    </div>	
                            </form>
                            </div>
                        </div>
                        <div className="span_1_of_2">
                            <div className="address">
                                <div className="span_1_of_2_pic">
                                    <img src={ImageIcon1} alt=""></img>
                                </div>
                                <div className="span_1_of_2_text">
                                    <p>The Lorem Ipsum Dolor Sit,</p>
                                    <p>22-56-2-9 Sit Amet,</p>
                                    <p>the perspiciatis,</p>
                                    <p>USA</p>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="address">
                                <div className="span_1_of_2_pic">
                                    <img src={ImageIcon2} alt=""></img>
                                </div>
                                <div className="span_1_of_2_text">
                                    <h3><a href="mailto:info@mycompany.com">info(at)mycompany.com </a></h3>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div className="address">
                                <div className="span_1_of_2_pic">
                                    <img src={ImageIcon3} alt=""></img>
                                </div>
                                <div className="span_1_of_2_text">
                                    <h4>01234 444 777</h4>
                                </div>                                
                                <div className="clear"></div>
                            </div>
                            <div className="social-icons">
                                    <h3>find us here</h3>
                                <ul>
                                    <li><Link href="#" target="_blank"></Link></li>
                                    <li><Link href="#" target="_blank"></Link></li>
                                    <li><Link href="#" target="_blank"></Link></li>
                                    <li><Link href="#" target="_blank"></Link></li>
                                    <li><Link href="#" target="_blank"></Link></li>
                                </ul>
                                </div>
                        </div>
                        <div className="clear"></div>
                    </div>	
                </div>
            </div>
        </div>


        <a href="#" id="toTop">To Top</a>


    </>
    );
}

export default App;