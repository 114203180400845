import React from 'react';
import { Link } from 'react-router-dom';
import { Hero } from "../../components/header";

function Support() {
    const pageTitle = 'Support';
    document.title = global.AppName + ' - ' + pageTitle;
    return (
        <>
            <Hero title={"Received Support"} subtitle={"To help build the tannery"}></Hero>

            {/* start main */}
            <div className="main_bg">
                <div className="wrap">
                    <div className="main" id="about">
                        <h2>Travel Blog</h2>
                        {/* <h3>some things you should know about us</h3> */}
                        <section>
                            <p>
                            </p>
                        </section>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Support;