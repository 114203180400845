import React from "react";
import { NavLink } from 'react-router-dom';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

import ImageLogo from "../../assets/images/great-cotmarsh-farm-logo.webp";

function Header({ currentPage }) {
    function toggleMobileMenu() {
        var x = document.getElementById("menuItems");
        x.classList.toggle("show")
    }
    return (
        <>
            <header>
                <div className="wrap">
                    <nav>
                        <div className="logo">
                            <h1><a href="/"><img src={ImageLogo} alt="Cotmarsh Tannery Logo"></img></a></h1>
                        </div>
                        <div className="menu">
                            <NavLink id="open" title="Mobile menu" to="#" className="menuToggle" onClick={toggleMobileMenu}><span>Menu</span><FontAwesomeIcon  icon={faBars} /></NavLink>
                            <ul id="menuItems">
                                <li id="home"><NavLink to="/" onClick={toggleMobileMenu}>Home</NavLink></li>
                                {/* <li className="dropdown">
                                    <NavLink to="/the-tannery">The Tannery</NavLink> */}
                                    {/* <ul>
                                        <li><NavLink to="/the-tannery/veg-tanning">Vegetable Tanning</NavLink></li>
                                    </ul> */}
                                {/* </li> */}
                                <li><NavLink to="/churchill-fellowship" onClick={toggleMobileMenu}>Churchill Fellowship</NavLink></li>
                                {/* <li><NavLink to="/makers-directory">The Makers Directory</NavLink></li> */}
                                <li><NavLink to="/survey" onClick={toggleMobileMenu}>Survey</NavLink></li>
                                {/* <li><NavLink to="/about-us">About Us</NavLink></li> */}
                                <li><NavLink to="/contact-us">Contact Us</NavLink></li>
                                <li id="close" onClick={toggleMobileMenu}>
                                    <NavLink to="#"><FontAwesomeIcon  icon={faXmark} /><span>Close</span></NavLink>
                                </li>
                            </ul>

                        </div>
                        <div className="clear"></div>
                    </nav>
                </div>
                {
                    process.env.NODE_ENV === 'development' && 
                    <div id="devbuild">
                        This is a Dev Build
                    </div>
                }
            </header>
        <noscript>You need to enable JavaScript to run this app.</noscript>
        </>
      
    );
  }

export default Header;    

function Hero({title, subtitle, logo }) {
    return (
        <>
            {/* start slider */}
            <div className="slider_bg">
                <div className="wrap">
                    <div className="slider" id="home">
                        <div className="slider_text">
                            <h2>{title}</h2>
                            <h3>{subtitle}</h3>
                            {logo}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export { Hero };