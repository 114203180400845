import { useState } from "react";
import { Hero } from "../../components/header";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";

const App = () => {
    const pageTitle = 'Contact Us';
    document.title = global.AppName + ' - ' + pageTitle;

    const initialFormData = {
        firstname: '',
        surname: '',
        emailto: 'james@cotmarshtannery.co.uk',
        emailfrom: '',
        phone: '',
        title: '',
        message: '',
        captcha: '',
        websiteid: 'CotmarshTannery'
    };
    const [formData, setFormData] = useState(initialFormData);
    const resetFormData = () => {
        setFormData(() => initialFormData);
      };
      
    const [errormessage, setErrormessage] = useState('');
    // Used to see if the form has been submitted when validating 
    const [submitted, setSubmitted] = useState(false);

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleChange = (e) => {
        // set the form data on form field change and validate
        setFormData({ ...formData, [e.target.name]: e.target.value });    
        validateValues();    
      };

    const validateValues = (formSubmitting = false) => {        
        var valid = false;
        let errormessage = {};

        // if the form has been submitted set as submitted once for future validation
        if (formSubmitting) {
            setSubmitted(true);
        }

        // If form was submitted and field changed after submitted once (so already has errors show) the validate
        if(formSubmitting || submitted) {
            var field = document.getElementById('firstname');
            field.classList.add('error');
            if (field.value.length < 1) {
                errormessage.firstname = "First name is required";
            } else {
                field.classList.remove('error');
            }

            field = document.getElementById('surname');
            field.classList.add('error');
            if (field.value.length < 1) {
                errormessage.surname = "Surname is required";
            } else {
                field.classList.remove('error');
            }
            
            field = document.getElementById('emailfrom');
            field.classList.add('error');
            if (field.value.length < 1) {
                errormessage.emailFrom = "Email address is required";
            } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(field.value)) {
                errormessage.emailFrom = "Invalid email address";
            } else {
                field.classList.remove('error');
            }
            
            field = document.getElementById('message');
            field.classList.add('error');
            if (field.value.length < 1) {
                errormessage.message = "A message is required";
            } else if (field.value.length < 4) {
                errormessage.message = "A message has to be over 4 characters";
            } else {
                field.classList.remove('error');
            }

            // check if all validation passed (fail safe)
            if (Object.keys(errormessage).length === 0)
            {
                valid = true;
            }
            setErrormessage(errormessage);
        }
        return valid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(validateValues(true)) {

            const token = await executeRecaptcha("contact_form");

            var formSubmitData = formData;
            formSubmitData.captcha = token;

        //   fetch('https://creweallen-function.azurewebsites.net/api/SendEmail', { 
        //   fetch('http://localhost:7071/api/SendEmail', { 
            fetch(process.env.REACT_APP_API_ENDPOINT, { 
                method: "POST",
                body: JSON.stringify(formSubmitData),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            }).then(
                (response) => (response.json())
            ).then((response)=> {
                // Hide the form whilst showing submit outcome
                document.getElementById('contact-form').style.display = 'none';
                if (response.status === 'success') {
                    // submit sucessful so reset form and set subitted flag to false for full form reset
                    resetFormData()
                    setSubmitted(false);
                    document.getElementById('contact-form-message-success').style.display = 'block'
                } else {
                    document.getElementById('contact-form-message-fail').style.display = 'block'
                    setFormData({...formData, errormessage: response.message});
                }
            }).catch ((error) => {
                // hide form whilst showing error message
                document.getElementById('contact-form').style.display = 'none';
                document.getElementById('contact-form-message-fail').style.display = 'block'
                setFormData({...formData, errormessage: error.message});
                // setFormData({...formData, errormessage: "A general error has occured."});
            });
        }
    };

    const closeMessage = e => {
        // close error / sucess message and reshow the form (either reset or with failed values in fields)
        e.parentNode.style.display='none';
        document.getElementById('contact-form').style.display = 'block';
    }

    return(
        <>            
            <Hero title={"Cotmarsh Tannery"} subtitle={"Traditional leather micro-tannery"}></Hero>

            {/* start main */}
            <div className="main_bg">
                <div className="wrap">
                    <div className="main" id="about">
                        <h2>Contact Us</h2>

                        <div id="contact-form-message-success" style={{display: 'none'}}>
                            <h4>Thank you</h4>
                            <p>
                                Your message has been sent and we will get back to you as soon as we can.
                            </p>
                            <input type="submit" className="primary" value="Close" onClick={(event) => closeMessage(event.target)} />
                        </div>                        
                        <div id="contact-form-message-fail" className="error" style={{display: 'none'}}>
                            <h4>Error</h4>
                            <p>
                                There appears to be a problem with the contacts form, please try again.
                            </p>
                            <p>
                                Error message: {formData.errormessage}
                            </p>
                            <input type="submit" className="primary" value="Close" onClick={(event) => closeMessage(event.target)} />
                        </div>
                        {/* TODO form validation */}
                        <form id="contact-form" onSubmit={handleSubmit} method="POST" noValidate >
                            <p>
                                If you want to get in touch with us then please use the contact form below:
                            </p>
                            <div>
                                <div className="col-6 col-12-xsmall">                                    
                                    <label htmlFor="firstname">Firstname<span className="error">*</span></label>
                                    <input type="text" name='firstname' id='firstname' placeholder='First name' value={formData.firstname} onChange={handleChange}/>
                                </div>
                                <div className="col-6 col-12-xsmall">                                    
                                    <label htmlFor="surname">Surname<span className="error">*</span></label>
                                    <input type="text" name='surname' id='surname' placeholder='Surname' value={formData.surname} onChange={handleChange} />
                                </div>
                                <div className="col-12">        
                                    <label htmlFor="emailfrom">Email address<span className="error">*</span></label>
                                    <input type="email" name="emailfrom" id="emailfrom" placeholder="Email" value={formData.emailfrom} onChange={handleChange} />
                                </div>
                                <div className="col-12">
                                    <label htmlFor="message">Message<span className="error">*</span></label>
                                    <textarea name="message" id="message" placeholder="Enter your message" rows="6" value={formData.message} onChange={handleChange}></textarea>
                                </div>
                                <div className="col-12">
                                    <input type="submit" className="primary" value="Send Message" />
                                </div>
                                {/* <div>{process.env.REACT_APP_API_ENDPOINT}</div> */}
                                <div id="ErrorSummary" className="col-12 error">
                                    <ul>
                                        {Object.keys(errormessage).map((key) => (
                                            <li key={key}>{errormessage[key]}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

// wrap the app component with the GoogleReCaptchaProvider
export default () => (
    <GoogleReCaptchaProvider reCaptchaKey="6LeU2n4pAAAAACRrmBrl6o6WbHSw-jRCtVZBiCd2" size="compact" badge="bottomright">
      <App />
    </GoogleReCaptchaProvider>
  );