import React from 'react';
import { Hero } from "../../components/header";
import { Link } from 'react-router-dom';
import './index.css';

import BlogPost from "../../components/blogpost";
import ImageLogo from "../../assets/images/churchill-fellowship-logo.webp";

function App() {
    const pageTitle = 'Churchill Fellowship';
    document.title = global.AppName + ' - ' + pageTitle;
    const logo = (
        <Link to='https://www.churchillfellowship.org/' title='Churchill Fellowship' target='_blank' className='image'>
            <img src={ImageLogo} alt='Churchill Fellowship logo'></img>
        </Link>
    );
    return (
        <>
            <Hero title={"Churchill Fellowship"} subtitle={"Travel Scholarship"} logo={logo}></Hero>

            {/* start main */}
            <div className="main_bg">
                <div className="wrap">
                    <div className="main" id="about">
                        <h2>Travel Blog</h2>
                        {/* <h3>some things you should know about us</h3> */}
                        <section>
                            <p>
                                In 2023 James was awarded a <Link to='https://www.churchillfellowship.org/' title='Churchill Fellowship' target='_blank'>Churchill Fellowship</Link> travel 
                                scholarship. The scholarship was to fund international travel to build his knowledge of tanning and to visit other tanneries abroad.
                            </p>

                            <p>
                                The travel was split into two parts:
                            </p>
                            <h3>Part 1</h3>
                            <h4>3 week tanning course for setting up a micro-tannery with Matt Richards - 
                                <Link to='https://braintan.com/' title='Traditional Tanners' target='_blank'>Traditional Tanners</Link> (Oregon, US)</h4> 
                            <p>
                                Matt is an expert traditional tanner with decades of experience. He is one of the few traditional veg tannery in the world with a micro-tannery.
                            </p>
                            
                            {/* <BlogPost id={"01"} mode={"link"}/> */}
                            <BlogPost id={"01"}/>
                            <BlogPost id={"02"}/>
                            <BlogPost id={"03"}/>
                            <BlogPost id={"04"}/>
                            <BlogPost id={"05"}/>
                            <BlogPost id={"06"}/>
                            <BlogPost id={"07"}/>
                            <BlogPost id={"08"}/>
                            <BlogPost id={"09"}/>
                            <BlogPost id={"10"}/>
                            <BlogPost id={"11"}/>
                            <BlogPost id={"12"}/>
                            <BlogPost id={"13"}/>
                            <BlogPost id={"14"}/>
                            <BlogPost id={"15"}/>
                            <BlogPost id={"16"}/>
                            <BlogPost id={"17"}/>
                            <BlogPost id={"18"}/>
                            <BlogPost id={"19"}/>
                        </section>
                        <section>                            
                            <h3>Part 2</h3>
                            <h4>3 weeks visiting some of the remaining veg tanneries in Europe.</h4>
                            <p>
                                In September I will be visiting the <Link to='https://www.lineapelle-fair.it/en' title='Lineapelle' target='_blank'>Lineapelle</Link> the international 
                                exhibition of leather, accessories, components, fabrics, synthetics and models in Milan, Italy.
                            </p>
                            <p>
                                Then in November I am spending a week visiting the tanneries of 
                                the <Link to='https://www.pellealvegetale.it/en/consortium/' title='Genuine Italian Vegetable-Tanned Leather Consortium' target='_blank'>Genuine Italian Vegetable-Tanned Leather Consortium</Link> in 
                                Tuscany, Italy. Including:
                            </p>
                            <ul>
                                <li><Link to='https://www.conceriailponte.it' title='Conceria Il Ponte' target='_blank'>Conceria Il Ponte</Link></li>
                                <li><Link to='https://www.laperlaazzurra.com' title='Conceria La Perla Azzurra' target='_blank'>Conceria La Perla Azzurra</Link></li>
                            </ul>
                            <p>as well as the tannery:</p>
                            <ul>
                                <li><Link to='https://www.conceriamaryam.it' title='Maryam S.r.l.' target='_blank'>Maryam S.r.l.</Link></li>
                            </ul>
                            <p>and various tannery machine manufacturers.</p>
                            <p>
                                Finally, I am also organising visits to the German tanneries <Link to='https://kilger.de/en/' title='Kilger Tannery' target='_blank'>Kilger</Link> and <Link to='https://www.lederfabrik-renz.de/' title='August Renz' target='_blank'>August Renz</Link>.
                            </p>
                        </section>

                        <div className="clear"></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default App;