import { lazy, Suspense } from 'react';
import './index.css';
import Loading from '../Loading.js';

function BlogPost({ id, mode="full" }) {
    const Post =lazy(() => import('./posts/'+id));
    return (
        <Suspense fallback={<Loading />}>
            <Post id={id} mode={mode} ></Post>
        </Suspense>
    )
  }
    
  export default BlogPost;
  